$(document).ready(function (){

	var cc = getCookie("consentConfirmed");
	if(cc == "true"){
		$(".cookie-consent").css("display","none");
	}

	$('a.ankor-scroll').on('click',function(e) {
		
		if($(".mobile-logo").css("display") === "block"){
			$("nav.start").find("ul").slideUp();
		}
		
		 e.preventDefault();
		 var target = this.hash;
		 var $target = $(target);
		 $('html, body').stop().animate({
		  'scrollTop': $target.offset().top - $target.height()
		 }, 900, 'linear', function (e) {
		  window.location.hash = target;
		  
		 }); 
	});
 
    var wsPos = "upper";
    var lastPos = "upper";
    var initOffsetTop = $('.start-menu').offset().top;
	
	$(window).resize(function(){
		if($(".mobile-logo").css("display") === "block"){
			$("nav.start").find("ul").css("display","inline-flex");
		}
		if(window.innerWidth <= 740 && $("nav.start ul").css("display") === "inline-flex"){
			$("nav.start").find("ul").css("display","none");
		}
		if(window.innerWidth > 740 && $("nav.start ul").css("display") === "none"){
			$("nav.start").find("ul").css("display","inline-flex");
			initOffsetTop = $('.start-menu').offset().top;
		}

		if(window.innerWidth > 992 && $(".start-language-switch ul").css("display") === "none"){
			$(".start-language-switch ul").css("display","block");
		}
	});
    
	$(window).scroll(function () {
		if($('header').hasClass('index-page')){
            lastPos = wsPos;  
            var currentScrollPos = $(window).scrollTop();
			if(currentScrollPos > initOffsetTop){               
				wsPos = "lower"
			}
			else if(currentScrollPos <= initOffsetTop){
                wsPos = "upper"
			}

            if(wsPos != lastPos){
                if(wsPos == "upper"){
                    $('.start-menu').removeClass("fixed-start");
                    $('.back-to-up').css("display","none");
                }
                else if(wsPos == "lower"){
                    $('.start-menu').addClass("fixed-start");
                    $('.back-to-up').css("display","block");
                }
            }
        }     
	});


	$(".cookies.btn").click(function(){
		setCookie("consentConfirmed","true",7);
		$(".cookie-consent").slideToggle();
	});
	
	$(".switch.btn").click(function(){
		$(".start-language-switch").find("ul").slideToggle();
	});
	
	$(".mainmenu.btn").click(function(){
		$("nav.start").find("ul").slideToggle();
	});
	/*
	$(".impressum-link").click(function(){
		$("#Impressum").slideToggle();
		 var $target = $("#Impressum");
		 $('html, body').stop().animate({
		  'scrollTop': $target.offset().top - $target.height()
		 }, 900, 'linear', function (e) {
		  window.location.hash = '#Impressum';
		});
	});*/

	$(".scroll-logo").click(function(element) {
		$('html, body').stop().animate({
		  'scrollTop': 0
		 }, 900, 'linear', function (e) {  
		  window.location.hash = "";
		 });
	});

	$(".mobile-logo").click(function(element) {
		$('html, body').stop().animate({
		  'scrollTop': 0
		 }, 900, 'linear', function (e) {  
		  window.location.hash = "";
		 });
	});

	$(".back-to-up").click(function(element) {
		$('html, body').stop().animate({
		  'scrollTop': 0
		 }, 900, 'linear', function (e) {  
		  window.location.hash = "";
		 });
	});

	$(".question").click(function(e) {
		var element = $(this);
		$(this).parent().find(".answer").toggle( "slow", function(e) {
			if(element.parent().find(".answer").css("display") == "block"){
				element.find("h2").find("i").removeClass("fa-plus");
		    	element.find("h2").find("i").addClass("fa-minus");
			}
		    else{
		    	element.find("h2").find("i").removeClass("fa-minus");
		    	element.find("h2").find("i").addClass("fa-plus");
		    }
	  });
	});
});


function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}